.footer {
  width: 747px;
  height: 24px;
  position: fixed;
  left: 50%;
  bottom: 26px;
  margin-left: -373px;
  z-index: 3;
  text-align: center;
  color: #909399;
  font-size: 12px;
  font-family:PingFangSC-Regular,PingFangSC;
  line-height: 24px;
  letter-spacing: 0;
  transition: color 2s ease 0s;
  a {
    color: inherit;
  }
  &.home {
    color: #fff;
    .goverment-records {
      .line {
        &::before {
          background: #fff;
        }
      }
    }
  }
  &.other {
    transition: color 0s;
    .goverment-records {
      .line {
        &::before {
          transition: background-color 0s;
        }
      }
    }
  }
  .company-info {
    float: left;
    width: 365px;
  }
  .goverment-records {
    width: 337px;
    float: left;
    margin-left: 44px;
    position: relative;
    .police {
      width: 20px;
      height: 20px;
      background-image: url('../../assets/images/footer/icon_police.png');
      background-size: cover;
      position: absolute;
      top: 2px;
      left: -22px;
    }
    .line {
      padding-left: 20px;
      &::before {
        content: '';
        width: 1px;
        height: 10px;
        background: #909399;
        position: absolute;
        left: 198px;
        top: 7px;
        transition: background-color 2s ease 0s;
      }
    }
  }
}