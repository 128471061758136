.indicator {
  width: 30px;
  height: 100px;
  position: fixed;
  right: 40px;
  top: 50%;
  z-index: 3;
  li {
    width: 30px;
    height: 20px;
    position: relative;
    &::before {
      content: '';
      width: 15px;
      height: 1px;
      position: absolute;
      top: 0;
      right: 0;
      background:rgba(33,33,33,0.5);
      transition: all 0.5s;
    }
    &.active {
      &::before {
        width: 30px;
      }
    }
  }
}