.about-us {
  width: 100%;
  height: 100%;
  background:linear-gradient(180deg,rgba(251,253,255,1) 0%,rgba(255,255,255,1) 100%);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  // 背景层
  .bg-layer {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/about/about_bg.jpg');
    background-size: cover;
    background-position: center bottom;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: opacity 0.5s 0.8s;
  }
  .page {
    width: 8.3rem;
    height: 6rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
  .rect {
    width:3rem;
    height:3rem;
    background:linear-gradient(135deg,rgba(50,251,252,1) 0%,rgba(49,86,255,1) 100%);
    border-radius:0.4rem;
    opacity:0.1;
    position: absolute;
    transition: top 0.8s ease 0.1s;
    z-index: 3;
  }
  .left-rect {
    top: 60%;
    left: 0;
    transform: translate(-2rem, -0.2rem) rotateZ(135deg);
    animation: 0.5s linear 0s 1 leftRect;
  }
  .right-rect {
    top: 0.65rem;
    right: 0;
    transform: translate(2rem, 1rem) rotateZ(135deg);
    animation: 0.5s linear 0s 1 rightRect;
  }
  // 第一页内容
  .first-page {
    top: 1.5rem;
    transition: top 0.8s ease 0.1s;
    animation: 0.5s linear 0s 1 landing;
    // 初衷标题
    .title {
      height:0.5rem;
      font-size:0.36rem;
      font-family:PingFangSC;
      font-weight:600;
      color:rgba(33,33,33,1);
      line-height:0.5rem;
      letter-spacing:1px;
    }
    // 初衷内容
    .destiny {
      width:8.3rem;
      margin-top: 0.36rem;
      font-size:0.18rem;
      font-family:PingFangSC;
      font-weight:500;
      color:rgba(33,33,33,1);
      line-height:0.4rem;
    }
    // 公司信息
    .company-info {
      margin-top: 1rem;
      font-family:PingFang-SC;
      color:rgba(33,33,33,1);
      line-height:0.24rem;
      letter-spacing: 0;
      .company-name {
        height:0.24rem;
        font-size:0.24rem;
        font-weight:500;
        margin-bottom: 0.18rem;
      }
      p {
        height:0.24rem;
        margin-bottom: 0.08rem;
        font-size:0.16rem;
        font-weight:400;
      }
    }
    .item-con {
      height: 0.24rem;
      margin-top: 0.54rem;
      line-height: 0.24rem;
      color: #212121;
    }
  }
  // 第二页
  .second-page {
    height: 4.8rem;
    transition: top 1s ease 0.1s, opacity 0.8s linear 0.3s;
    opacity: 0;
    .article {
      width:4.41rem;
      height:3.49rem;
      font-size:0.2rem;
      font-family:PingFangSC-Medium;
      font-weight:500;
      color:rgba(33,33,33,1);
      line-height:0.48rem;
      letter-spacing: 0;
    }
    .proverb {
      width:4.48rem;
      height:0.24rem;
      margin-top: 0.68rem;
      font-size:0.24rem;
      font-family:PingFangSC-Medium;
      font-weight:500;
      color:rgba(33,33,33,1);
      line-height:0.24rem;
      position: relative;
      &::after {
        content: '';
        width: 0;
        height: 0.03rem;
        background: #4BA2F3;
        position: absolute;
        bottom: -0.23rem;
        left: 0;
        transition: width 0.5s ease-in-out 1.2s;
      }
    }
  }
  // 第三页
  .third-page {
    width: 5.3rem;
    height: 3.36rem;
    top: calc(150% - 1.68rem + 1rem);
    opacity: 0;
    transition: top 0.8s ease 0.1s, opacity 0.3s linear 0.6s;
    .item-con {
      height: 0.24rem;
      line-height: 0.24rem;
      color: #212121;
      margin-bottom: 0.8rem;
      position: relative;
      .left {
        width: 2.56rem;
        font-size: 0.24rem;
        font-weight: bold;
        display: block;
        float: left;
      }
      .right {
        font-size: 0.16rem;
        font-weight: normal;
        display: block;
        float: left;
      }
      &.contact {
        .right {
          width: 2.5rem;
          position: absolute;
          left: 2.56rem;
          .seperator {
            padding-left: 0.07rem;
          }
        }
      }
    }
    // 联系我们
    // .contact {
    //   position: absolute;
    //   left: 256px;
    //   bottom: -20px;
    //   font-size: 14px;
    //   font-weight: 500;
    //   span {
    //     display: inline-block;
    //     width: 120px;
    //     height: 40px;
    //     border-radius: 2px;
    //     border: 1px solid #212121;
    //     line-height: 38px;
    //     text-align: center;
    //     margin-right: 12px;
    //     cursor: pointer;
    //   }
    // }
  }
  // 版权声明
  .copyright {
    width: 100%;
    font-size: 0.12rem;
    color: #909399;
    position: fixed;
    bottom: 0.28rem;
    text-align: center;
    z-index: 3;
    opacity: 1;
    letter-spacing: 0;
    .right {
      padding-left: 0.2rem;
    }
  }
  // 返回顶部按钮
  .back-to-top {
    width: 32px;
    height: 32px;
    background-image: url('../../assets/images/about/back.png');
    background-size: cover;
    position: fixed;
    bottom: 30px;
    right: 120px;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.3s linear 0.3s;
    z-index: 4;
    display: none;
  }
  &.step0 {
    .second-page {
      top: 100%;
      .proverb {
        &::after {
          width: 0;
        }
      }
    }
  }
  &.step1 {
    .left-rect {
      top: 30%;
    }
    .right-rect {
      top: -3.4rem;
    }
    .first-page {
      top: -5.36rem;
    }
    .second-page {
      top: calc(50% - 2.40rem);
      opacity: 1;
      .proverb {
        &::after {
          width: 4.40rem;
        }
      }
    }
  }
  // 第三页激活
  &.step2 {
    .left-rect {
      top: -80%;
    }
    .right-rect {
      top: -8.40rem;
    }
    .circle {
      opacity: 0.1;
    }
    .first-page {
      top: calc(-100% - 5.36rem);
    }
    .second-page {
      top: calc(-50% - 2.4rem);
    }
    .third-page {
      top: calc(50% - 1.68rem);
      opacity: 1;
    }
    .bg-layer {
      opacity: 0;
    }
    .copyright {
      bottom: 0.28rem;
      opacity: 1;
    }
    .back-to-top {
      opacity: 1;
    }
  }
}

@keyframes landing {
  0% {
    opacity: 0;
    margin-top: -0.5rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes leftRect {
  0% {
    opacity: 0;
    transform: translate(-2.5rem, -0.2rem) rotateZ(135deg);
  }
  100% {
    opacity: 0.1;
    transform: translate(-2rem, -0.2rem) rotateZ(135deg);
  }
}
@keyframes rightRect {
  0% {
    opacity: 0;
    transform: translate(2.5rem, 1rem) rotateZ(135deg);
  }
  100% {
    opacity: 0.1;
    transform: translate(2rem, 1rem) rotateZ(135deg);
  }
}
