header {
  height: 80px;
  padding: 0 40px 0 70px;
  font-size: 14px;
  position: relative;
  z-index: 3;
  .logo {
    width: 200px;
    height: 100%;
    float: left;
    line-height: 80px;
    color: #212121;
    position: relative;
    cursor: pointer;
    .logo-icon {
      width: 28px;
      height: 28px;
      background-image: url('../../assets/images/header/logo.png');
      background-size: cover;
      position: absolute;
      left: -38px;
      top: 26px;
    }
  }
  ul {
    width: 222px;
    height: 100%;
    float: right;
    display: flex;
    justify-content: space-between;
    line-height: 80px;
    li {
      color: rgba(33, 33, 33, 0.6);
      a {
        color: rgba(33, 33, 33, 0.6);
        font-weight: 600;
        &.active {
          color: rgba(33, 33, 33, 1);
        }
      }
    }
  }
  &.home {
    .logo {
      color: #fff;
    }
    ul {
      li {
        color: rgba(255, 255, 255, 0.6);
        &:first-of-type {
          a {
            color: #fff;
          }
        }
        a {
          color: rgba(255, 255, 255, 0.6);
          &.active {
            color: #fff;
          }
        }
      }
    }
  }
  // 404页面不展示导航条
  &.hidden {
    display: none;
  }
}