@import './reset.scss';

html, body {
  width: 100%;
  height: 100%;
  font-size: 100px;
}
#root {
  width: 100%;
  height: 100%;
}
.main {
  width: 100%;
  height: 100%;
}