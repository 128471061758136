.opening-animation {
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  transition: opacity 1s ease 0s;
  &.hidden {
    z-index: 1;
    opacity: 0;
    // animation: fadeout 1s ease 0s;
  }
}
video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@keyframes fadeout {
  0% {
    opacity: 1;
    z-index: 2;
  }
  50% {
    opacity: 1;
    z-index: 2;
  }
  100% {
    opacity: 0;
    z-index: 1;
  }
}