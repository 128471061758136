.function-intro {
  width: 100%;
  height: 100%;
  background:linear-gradient(180deg,rgba(251,253,255,1) 0%,rgba(255,255,255,1) 100%);
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 2;
  .top-circle {
    width:200px;
    height:200px;
    background:linear-gradient(135deg,rgba(50,251,252,1) 0%,rgba(49,86,255,1) 100%);
    border-radius:50%;
    opacity:0.1;
    position: absolute;
    z-index: 4;
    left: 0;
    top: 0;
    transform: translate(77%, -62%);
    transition: all 1s;
    &.page1 {
      width: 155px;
      height: 155px;
      transform: translate(148%, 87.7%);
    }
    &.page2 {
      width: 280px;
      height: 280px;
      transform: translate(-50%, 84.3%);
    }
    &.page3 {
      width: 331px;
      height: 331px;
      transform: translate(-45.3%, 211%);
    }
    &.page4 {
      width: 377px;
      height: 377px;
      transform: translate(-37.1%, 42.4%);
    }
  }
  .bottom-circle {
    width:300px;
    height:300px;
    background:linear-gradient(135deg,rgba(50,251,252,1) 0%,rgba(49,86,255,1) 100%);
    border-radius:50%;
    opacity:0.1;
    position: absolute;
    z-index: 4;
    right: 0;
    bottom: 0;
    transform: translate(-28.3%, 57%);
    transition: all 1s;
    &.page1 {
      width: 227px;
      height: 227px;
      transform: translate(-58.1%, -28.6%);
    }
    &.page2 {
      width: 189px;
      height: 189px;
      transform: translate(-101.6%, -140.2%);
    }
    &.page3 {
      width: 230px;
      height: 230px;
      transform: translate(49.7%, -314.8%);
    }
    &.page4 {
      width: 300px;
      height: 300px;
      transform: translate(50%, -20%);
    }
  }
}