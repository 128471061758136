/* reset.css */
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
p,
blockquote,
table,
th,
td,
textarea {
    margin: 0;
    padding: 0;
}
body {
    font: 12px/1.5 '-apple-system-font', 'Helvetica Neue', 'Tahoma', 'PingFang SC', 'Microsoft Yahei', 'SimSun', 'sans-serif';
    color: #333;
}

button,
input,
select,
textarea {
    font: 12px/1.5 '-apple-system-font', 'Helvetica Neue', 'Tahoma', 'PingFang SC', 'Microsoft Yahei', 'SimSun', 'sans-serif';
    color: #333;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset,
img {
    border: 0;
    vertical-align: middle;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
    font-style: normal;
    font-weight: normal;
}

// 列表默认样式
ul, ol {
  list-style: none;
}

// 链接默认样式
a {
  color: #333;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:active, &:focus {
    outline: none;
  }
}

caption,
th {
    text-align: left;
}

input {
    outline: none;
    &::-moz-focus-inner {
        border: none;
    }
}

// 清除浮动
.clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}