.func-content {
  width: 750px;
  height: 600px;
  position: absolute;
  top: calc(50% - 300px);
  left: calc(50% - 375px);
  .phone-container {
    width: 523px;
    height: 673px;
    position: absolute;
    top: 0;
    z-index: 1;
    transition: left 1s ease 0.1s;
    animation: fadein 0.5s ease 0s;
    .phone {
      width: 100%;
      height: 100%;
      position: relative;
      .iphone-content {
        width: 254px;
        height: 550px;
        background-size: cover;
        position: absolute;
        top: 17px;
        left: 26px;
        z-index: 1;
        transition: opacity 1s ease 0s;
        opacity: 0;
        &.iphone-content0 {
          background-image: url('../../../../assets/images/functionIntro/iphone1.png');
        }
        &.iphone-content1 {
          background-image: url('../../../../assets/images/functionIntro/iphone2.png');
        }
        &.iphone-content2 {
          background-image: url('../../../../assets/images/functionIntro/iphone3.png');
        }
        &.iphone-content3 {
          background-image: url('../../../../assets/images/functionIntro/iphone4.png');
        }
        &.iphone-content4 {
          background-image: url('../../../../assets/images/functionIntro/iphone5.png');
        }
      }
      .iphone-bg {
        width: 100%;
        height: 100%;
        background-image: url('../../../../assets/images/functionIntro/iphone_bg.png');
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
      }
    }
  }
  .desc-container {
    width: 358px;
    position: absolute;
    top: 78px;
    z-index: 2;
    transition: left 1s ease 0s;
    // 描述内容
    &.flashing1 {
      animation: 1s ease 0s flashing1;
    }
    &.flashing2 {
      animation: 1s ease 0s flashing2;
    }
    &.fadein {
      animation: 1s ease 0s fadein;
    }
    .desc {
      color: #212121;
      position: absolute;
      opacity: 0;
      transition: opacity 0s linear 0.4s;
      z-index: 1;
      font-family:PingFangSC;
      // 大标题
      h2 {
        font-size: 44px;
        font-weight: 500;
        line-height: 56px;
        margin-bottom: 24px;
      }
      // 小标题
      h3 {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 8px;
        margin-top: 20px;
      }
      p {
        width: 362px;
        height: 84px;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0;
      }
      .buttons {
        position: absolute;
        width: 435px;
        font-size: 12px;
        .button {
          text-align: center;
          display: inline-block;
          height:48px;
          margin-left: 20px;
          background:rgba(228,231,237,1);
          border-radius:4px;
          font-size: 14px;
          font-weight:500;
          color:rgba(33,33,33,1);
          line-height: 48px;
          cursor: pointer;
          &:hover {
            background-color: rgba(228,231,237,0.8);
          }
          i {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            background-size: cover;
            display: inline-block;
            vertical-align: text-top;
          }
        }
        .store {
          width: 172px;
          &.apple {
            margin-left: 0;
            i {
              background-image: url('../../../../assets/images/home/Apple@2x.png');
            }
          }
          &.android {
            i {
              background-image: url('../../../../assets/images/home/Android@2x.png');
            }
          }
        }
        .qr {
          width: 48px;
          background-image: url('../../../../assets/images/home/qr.png');
          background-size: cover;
          background-repeat: no-repeat;
          vertical-align: bottom;
          position: relative;
          .qr-code {
            width: 0;
            height: 0;
            background-color: #fff;
            background-image: url('../../../../assets/images/home/qr_android.png');
            background-size: 80% 80%;
            background-position: center center;
            background-repeat: no-repeat;
            transition: all 0.5s;
            position: absolute;
            left: 24px;
            top: 24px;
          }
          &.active {
            .qr-code {
              width: 220px;
              height: 220px;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
  &.page0 {
    .phone-container {
      left: 0;
      .iphone-content0 {
        opacity: 1;
      }
    }
    .desc-container {
      left: 392px;
      .desc0 {
        opacity: 1;
        z-index: 2;
      }
    }
  }
  &.page1 {
    .phone-container {
      left: 453px;
      .iphone-content1 {
        opacity: 1;
      }
    }
    .desc-container {
      left: 0;
      .desc1 {
        opacity: 1;
        z-index: 2;
      }
    }
  }
  &.page2 {
    .phone-container {
      left: 0;
      .iphone-content2 {
        opacity: 1;
      }
    }
    .desc-container {
      left: 392px;
      .desc2 {
        opacity: 1;
        z-index: 2;
      }
    }
  }
  &.page3 {
    .phone-container {
      left: 453px;
      .iphone-content3 {
        opacity: 1;
      }
    }
    .desc-container {
      left: 0;
      .desc3 {
        opacity: 1;
        z-index: 2;
      }
    }
  }
  &.page4 {
    .phone-container {
      left: 0;
      .iphone-content4 {
        opacity: 1;
      }
    }
    .desc-container {
      left: 392px;
      .desc4 {
        opacity: 1;
        z-index: 2;
      }
    }
  }
}
// 功能介绍内容初始淡入动画
@keyframes fadein {
  0% {
    opacity: 0;
  }
  // 60% {
  //   opacity: 0;
  // }
  100% {
    opacity: 1;
  }
}

// 内容左右滑动时消失淡入动画
@keyframes flashing1 {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flashing2 {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}